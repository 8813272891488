import { effect, inject, Injectable, signal } from '@angular/core';
import { APP_STATE } from '@gth-legacy';
import { eventLoadListByLatLng } from '@gth-legacy';
import { Store } from '@ngrx/store';
import { GthEventItemModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { GthPlaceModel } from '@sentinels/models/place';
import { authReducer } from '@sentinels/state/features/auth/reducers';
import { eventReducer } from '@sentinels/state/features/events/reducers';
import { selectJoinersWithEventsByLatLng } from '@sentinels/state/features/joiners/selectors';
import { placesLoadListByLatLng } from '@sentinels/state/features/places/actions';
import { placesReducer } from '@sentinels/state/features/places/reducers';
import { selectPlacesByLatLng } from '@sentinels/state/features/places/selectors';
import { loadTeamsListByLatLng } from '@sentinels/state/features/teams/actions';
import { teamsReducer } from '@sentinels/state/features/teams/reducers';
import { selectTeamsByLatLng } from '@sentinels/state/features/teams/selectors';
import { userLoadListByLatLng } from '@sentinels/state/features/user/actions';
import { selectUsersByLatLng } from '@sentinels/state/features/user/selectors';
import { BehaviorSubject, take } from 'rxjs';
import { delay, finalize, first, tap } from 'rxjs/operators'; // Import LocationService

import { ArkSearchBarContext, ArkSearchBarFilter } from '../../../../../../ark/src/public-api';
import { LocationService } from './location.service';

interface LatLngMapItem {
  id: string;
  item: any;
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root',
})
export class DiscoveryService {
  private store = inject(Store<APP_STATE>);
  private locationService = inject(LocationService);
  isLoading$ = new BehaviorSubject<boolean>(false);

  eventContext = signal<ArkSearchBarContext>('events' as ArkSearchBarContext);

  public participants = signal<GthUserModel[]>([]);
  public events = signal<{ event: GthEventItemModel; joiners: any[] }[]>([]);
  public teams = signal<GthTeamModel[]>([]);
  public places = signal<GthPlaceModel[]>([]);

  constructor() {
    // Setting up the reducers in the store
    this.store.addReducer('event', eventReducer);
    this.store.addReducer('teams', teamsReducer);
    this.store.addReducer('auth', authReducer);
    this.store.addReducer('places', placesReducer);

    effect(
      () => {
        this.loadDataFromSelectors();
        this.dispatchLoad();
      },
      { allowSignalWrites: true },
    );
  }

  /**
   * Method to dispatch and load places, events, communities,
   * and participants based on location.
   */
  public dispatchLoad(): void {
    this.isLoading$.next(true);
    this.store.dispatch(eventLoadListByLatLng(this.locationService.locationWithBounds()));
    this.store.dispatch(userLoadListByLatLng(this.locationService.locationWithBounds()));
    this.store.dispatch(loadTeamsListByLatLng(this.locationService.locationWithBounds()));
    this.store.dispatch(placesLoadListByLatLng(this.locationService.locationWithBounds()));
  }

  /**
   * Load data into signals from the store selectors based on the user's location.
   * @param {ArkSearchBarFilter} filter
   */
  public loadDataFromSelectors(filter?: ArkSearchBarFilter): void {
    const location = this.locationService.locationWithBounds();
    const { lat, lng, bounds } = location;

    this.store
      .select(selectUsersByLatLng(lat, lng, 100, filter, bounds))
      .pipe(
        tap((data) => {
          this.participants.set(data.users);
        }),
        delay(500),
        first(),
        finalize(() => {
          if (this.eventContext() === 'participants') this.isLoading$.next(false);
        }),
      )
      .subscribe();

    this.store
      .select(selectJoinersWithEventsByLatLng(lat, lng, 100, filter, bounds))
      .pipe(
        tap((events) => {
          this.events.set(events.eventsAndJoiners);
        }),
        delay(500),
        first(),
        finalize(() => {
          if (this.eventContext() === 'events') this.isLoading$.next(false);
        }),
      )
      .subscribe();

    this.store
      .select(selectTeamsByLatLng(lat, lng, 100, filter, bounds))
      .pipe(
        tap((teams) => {
          this.teams.set(teams.teams);
        }),
        delay(500),
        first(),
        finalize(() => {
          if (this.eventContext() === 'communities') this.isLoading$.next(false);
        }),
      )
      .subscribe();

    this.store
      .select(selectPlacesByLatLng(lat, lng, bounds, undefined, filter))
      .pipe(
        tap((places) => {
          this.places.set(places.places);
        }),
        delay(500),
        first(),
        finalize(() => {
          if (this.eventContext() === 'places') this.isLoading$.next(false);
        }),
      )
      .subscribe();
  }

  /**
   * Returns lat-lng mappings for communities, places, events,
   * or participants based on the input name.
   * @param {ArkSearchBarContext} name
   * @return {LatLngMapItem[]}
   */
  public latlngMap(
    name: ArkSearchBarContext,
  ): LatLngMapItem[] {
    switch (name) {
      case 'events':
        return this.mapEvents();
      case 'participants':
        return this.mapParticipants();
      case 'communities':
        return this.mapCommunities();
      case 'places':
        return this.mapPlaces();
      default:
        return [];
    }
  }

  private mapEvents() {
    return this.events().map((event) => ({
      lat: event.event.location.lat,
      lng: event.event.location.lng,
      id: event.event.id,
      item: event,
    }));
  }

  private mapParticipants() {
    return this.participants().map((participant) => ({
      lat: participant.defaultCity.lat,
      lng: participant.defaultCity.lng,
      id: participant.uid,
      item: participant,
    }));
  }

  private mapCommunities() {
    return this.teams().map((team) => ({
      lat: team.location.lat,
      lng: team.location.lng,
      id: team.id,
      item: team,
    }));
  }

  private mapPlaces() {
    return this.places().map((place) => ({
      lat: place.address.lat,
      lng: place.address.lng,
      id: place.id,
      item: place,
    }));
  }

  setLocation(lat: number, lng: number, name: string) {
    this.locationService.setLocation(lat, lng, name);
  }
}
